.ReactModal__Body--open {
  overflow: hidden !important;
  position: fixed;
  width: 100%;
}

.crop-container {
   min-height: 400px;
}

.cropper-modal{
  min-width: 60%;
  max-height: 540px;
  height: auto;
}

@media(max-width: 992px){
  .cropper-modal{
    min-width: 100%;
    margin: 0;
  }
}
.image-cropper .controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}

.image-cropper .slider {
  padding: 22px 0px;
}

.image-cropper .zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #3f51b5;
  width: 100%;
  padding: 0;
}

.image-cropper .zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.image-cropper .zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.image-cropper .controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.image-cropper .controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

@media (max-width: 992px) {
  .ReactModal__Content {
      overflow: auto !important;
  }
}